import { Injectable, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private readonly toastr: ToastrService = inject(ToastrService);
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly defaultOptions = {
    positionClass: 'toast-top-right'
  };

  public showSuccess(message: string, title?: string): void {
    this.toastr.success(this.translate.instant(message), title, this.defaultOptions);
  }

  public showError(message: string, title?: string): void {
    this.toastr.error(this.translate.instant(message), title, this.defaultOptions);
  }

  public showWarning(message: string, title?: string): void {
    this.toastr.warning(this.translate.instant(message), title, this.defaultOptions);
  }
}
