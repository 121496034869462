import { AppConstants } from '@app/app.constants';
import { NgxGdprCookieConsentProviderConfig } from 'ngx-gdpr-cookie-consent/lib/service';

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) {
    return parts.pop()?.split(';').shift();
  } else {
    return undefined;
  }
};

// Factory function
export const getCookieTypes = (): NgxGdprCookieConsentProviderConfig => {
  const language = getCookie(AppConstants.STORAGE_PREFIX + AppConstants.USER_LANGUAGE) || navigator.language || 'en';
  const isGerman = language.startsWith('de');
  const essentialTitle = isGerman ? 'Essenziell' : 'Essential';
  const essentialDescription = isGerman
    ? 'Essenzielle Services ermöglichen grundlegende Funktionen und sind für das ordnungsgemäße Funktionieren der Website erforderlich.'
    : 'Essential services enable basic functionality and are necessary for the Site to function properly.';
  const statisticsTitle = isGerman ? 'Statistik' : 'Statistics';
  const statisticsDescription = isGerman
    ? 'Statistik-Cookies sammeln Nutzungsdaten, die uns Aufschluss darüber geben, wie unsere Besucher mit unserer Website umgehen.'
    : 'Statistical cookies collect usage data that gives us information about how our visitors interact with our website.';

  return {
    cookieTypes: [
      {
        id: 'essential',
        name: essentialTitle,
        description: essentialDescription,
        disabled: true,
        selected: true
      },
      {
        id: 'external',
        name: statisticsTitle,
        description: statisticsDescription,
        selected: true,
        scripts: [
          {
            url: 'https://www.googletagmanager.com/gtag/js?id=G-8FTH0WCCRG',
            defer: true,
            async: true
          }
        ]
      }
    ]
  };
};
