import { HttpErrorService } from '../http-error/http-error.service';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  private readonly httpErrorService: HttpErrorService = inject(HttpErrorService);

  constructor() {
    setTimeout(async () => {
      this.httpErrorService.initialize();
    }, 1);
  }
}
