<footer>
  <div class="boxed">
    <div class="s-space-between">
      <!-- logo  -->
      <img src="assets/webpunks-secrets.svg" alt="WEBPUNKS Secrets" class="logo-footer hide-mobile" />
      <!-- footer cta -->
      <a
        href="https://chromewebstore.google.com/detail/secret-sharer/efpkkmabcoocmbipbphmoheaiidcbfll"
        target="_blank"
        title="{{ 'buttons_get_extension' | translate }}"
        class="s-button-primary hide-mobile">
        <img src="assets/chrome.svg" height="18" width="18" alt="{{ 'buttons_get_extension' | translate }}" />
        {{ 'buttons_get_extension' | translate }}</a
      >
    </div>

    <hr class="hide-mobile" />
    <div class="footer-grid">
      <div class="column-1">
        <p class="column-title">{{ 'labels_services' | translate }}</p>
        <a
          target="_blank"
          title="{{ 'buttons_web_dev' | translate }}"
          href="https://www.webpunks.at/webentwicklung-agentur/"
          >{{ 'buttons_web_dev' | translate }}</a
        >
        <a
          target="_blank"
          title="{{ 'buttons_app_dev' | translate }}"
          href="https://www.webpunks.at/app-entwicklung-agentur/"
          >{{ 'buttons_app_dev' | translate }}</a
        >
        <a
          target="_blank"
          title="{{ 'buttons_online_marketing' | translate }}"
          href="https://www.webpunks.at/online-marketing-agentur/"
          >{{ 'buttons_online_marketing' | translate }}</a
        >
      </div>
      <div class="column-2">
        <p class="column-title">{{ 'labels_legal' | translate }}</p>
        <a target="_blank" title="{{ 'buttons_imprint' | translate }}" href="https://www.webpunks.at/impressum/">{{
          'buttons_imprint' | translate
        }}</a>
        <a target="_blank" title="{{ 'buttons_privacy' | translate }}" href="https://www.webpunks.at/datenschutz/">{{
          'buttons_privacy' | translate
        }}</a>
        <a target="_blank" title="{{ 'buttons_terms' | translate }}" href="https://www.webpunks.at/agb/">{{
          'buttons_terms' | translate
        }}</a>
      </div>
      <div class="column-3">
        <p class="no-margin-top">
          <span class="bold">{{ 'labels_footer_info' | translate }}</span
          ><br />
          {{ 'labels_wp_services' | translate }}
        </p>
        <p href="https://www.webpunks.at/agb/" target="”_blank”">
          {{ 'labels_wp_location' | translate }}<br />
          {{ 'labels_tel' | translate }}
          <a title="{{ 'buttons_wp_tel' | translate }}" href="tel:+4368181301712">{{ 'buttons_wp_tel' | translate }}</a>
          | {{ 'labels_mail' | translate }}
          <a title="{{ 'buttons_wp_mail' | translate }}" href="mailto:hello@webpunks.at">{{
            'buttons_wp_mail' | translate
          }}</a>
        </p>
      </div>
    </div>
  </div>
</footer>
<!-- socket  -->
<div class="socket">
  <div class="boxed">
    <div class="footer-grid">
      <div class="column-4">
        <p class="copyright">{{ 'labels_copyright' | translate }}</p>
      </div>
      <div class="column-6">
        <p class="copyright">Version {{ version }}</p>
      </div>
      <div class="column-7">
        <p>
          <img
            class="language-icon"
            height="10px"
            width="10px"
            alt="{{ 'labels_de' | translate }}"
            src="./assets/german.svg" />
          <a (click)="onChangeLanguage('de')">{{ 'labels_de' | translate }}</a>
          |
          <img
            class="language-icon"
            height="10px"
            width="10px"
            alt="{{ 'labels_en' | translate }}"
            src="./assets/united-kingdom.svg" />
          <a (click)="onChangeLanguage('en')">{{ 'labels_en' | translate }}</a>
        </p>
      </div>
    </div>
  </div>
</div>
