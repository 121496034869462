import { AppConstants } from '@app/app.constants';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CookiesService } from '@app/services/cookies/cookies.service';
import { environment } from '@environment/environment';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  imports: [RouterLink, TranslateModule],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  private readonly cookiesService: CookiesService = inject(CookiesService);
  private readonly translate: TranslateService = inject(TranslateService);
  public year: number = new Date().getFullYear();
  public version: string = environment.version;

  public onChangeLanguage(lang: string): void {
    this.translate.use(lang);
    this.cookiesService.setCookie(AppConstants.USER_LANGUAGE, lang);
  }
}
