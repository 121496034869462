export const environment = {
  apiUrl: 'https://directus.secret.webpunks.at',
  baseUrl: 'https://secret.webpunks.at',
  directusCollections: {
    secrets: 'secrets',
    users: 'users'
  },
  production: true,
  version: '1.0.3'
};
