import { AppConstants } from '@app/app.constants';
import { CookiesService } from '@app/services/cookies/cookies.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

const defaultLanguage = 'en';
const validLanguages = ['en', 'de'];
/*
 * Will set the language to the user language if available, otherwise to the browser or default language
 */
export function translationInitializerFactory(cookiesService: CookiesService, translate: TranslateService) {
  return (): Promise<any> => {
    translate.addLangs(validLanguages);

    // first try to get the language from cookies
    const userLang = cookiesService.getCookie(AppConstants.USER_LANGUAGE);

    if (userLang && validLanguages.includes(userLang)) {
      translate.setDefaultLang(userLang);
      return firstValueFrom(translate.use(userLang));
    }

    let browserLang = defaultLanguage;
    try {
      // Get the first part of the browser language (e.g., 'en' from 'en-US')
      browserLang = navigator.language.split('-')[0];
    } catch (error) {
      console.error('Error determining browser language: ', error);
    }
    const langToUse = validLanguages.includes(browserLang) ? browserLang : defaultLanguage;
    translate.setDefaultLang(langToUse);
    return firstValueFrom(translate.use(langToUse));
  };
}
