import { AppConstants } from '@app/app.constants';
import { CookiesService } from '@app/services/cookies/cookies.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';

const defaultLocale = 'en-US';
const validLocales = ['en-US', 'de-DE'];

export function localeInitializerFactory(cookiesService: CookiesService): string {
  registerLocaleData(localeEn);
  registerLocaleData(localeDe);

  const userLang = cookiesService.getCookie(AppConstants.USER_LANGUAGE);
  let locale = defaultLocale;

  // check for user language stored in cookie
  if (userLang) {
    validLocales.some(validLocale => {
      if (userLang.startsWith(validLocale.split('-')[0])) {
        locale = validLocale;
      }
    });
  } else {
    // fallback to browser language
    const browserLang = navigator.language;
    validLocales.some(validLocale => {
      if (browserLang.startsWith(validLocale.split('-')[0])) {
        locale = validLocale;
      }
    });
  }
  return locale;
}
