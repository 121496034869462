import { AppInitService } from './services/app-init/app-init.service';
import { Component, inject } from '@angular/core';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterOutlet } from '@angular/router';

@Component({
  imports: [CookieConsentComponent, FooterComponent, HeaderComponent, RouterOutlet],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true
})
export class AppComponent {
  private readonly appInitService: AppInitService = inject(AppInitService);
}
