import { APP_INITIALIZER, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { AppComponent } from '@app/app.component';
import { CookiesService } from '@app/services/cookies/cookies.service';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { NgxGdprCookieConsentModule } from 'ngx-gdpr-cookie-consent';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@environment/environment';
import { getCookieTypes } from '@app/shared/utils/get-cookie-types';
import { localeInitializerFactory } from '@app/shared/factories/locale-init';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideToastr } from 'ngx-toastr';
import { routes } from '@app/app.routes';
import { translationInitializerFactory } from '@app/shared/factories/translations-init';
import { translationLoaderFactory } from '@app/shared/factories/translations-loader';

if (environment.production) {
  enableProdMode();
  window.console.log = (): void => {};
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(),
    provideToastr(),
    {
      provide: APP_INITIALIZER,
      useFactory: translationInitializerFactory,
      deps: [CookiesService, TranslateService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: localeInitializerFactory,
      deps: [CookiesService]
    },
    importProvidersFrom(
      NgxGdprCookieConsentModule.forRoot(getCookieTypes()),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translationLoaderFactory,
          deps: [HttpClient]
        }
      })
    )
  ]
}).catch(err => console.error(err));
