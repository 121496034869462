import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'secrets',
    pathMatch: 'full'
  },
  {
    path: 'secrets',
    loadChildren: () => import('./pages/secrets/secrets.routes').then(m => m.routes)
  },
  {
    path: '404',
    loadComponent: () => import('./pages/not-found/not-found.page').then(m => m.NotFoundPage)
  },
  {
    path: '503',
    loadComponent: () => import('./pages/error/error.page').then(m => m.ErrorPage)
  },
  // {
  //   path: 'dashboard',
  //   loadComponent: () => import('./pages/dashboard/dashboard.page').then(m => m.DashboardPage)
  // },
  // {
  //   path: 'login',
  //   loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  // },
  // {
  //   path: 'lost-pw',
  //   loadComponent: () => import('./pages/lost-pw/lost-pw.page').then(m => m.LostPwPage)
  // },
  // {
  //   path: 'set-pw',
  //   loadComponent: () => import('./pages/set-pw/set-pw.page').then(m => m.SetPwPage)
  // },
  // {
  //   path: 'mail-page',
  //   loadComponent: () => import('./pages/mail-page/mail.page').then(m => m.MailPage)
  // },
  // {
  //   path: 'register',
  //   loadComponent: () => import('./pages/register/register.page').then(m => m.RegisterPage)
  // },
  // {
  //   path: 'confirm-mail',
  //   loadComponent: () => import('./pages/confirm-mail/confirm-mail.page').then(m => m.ConfirmMailPage)
  // },
  // {
  //   path: 'settings',
  //   loadComponent: () => import('./pages/settings/settings.page').then(m => m.SettingsPage)
  // },
  { path: '**', redirectTo: '404' }
];
