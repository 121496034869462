import { DirectusHttpError } from '@app/interfaces/directus-http-error.interface';
import { DirectusService } from '../directus/directus.service';
import { Injectable, inject } from '@angular/core';
import { ToastService } from '../toast/toast.service';
import { filter, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {
  private readonly directusService: DirectusService = inject(DirectusService);
  private readonly toastService: ToastService = inject(ToastService);

  public initialize(): void {
    this.directusService.currentError
      .pipe(
        filter((error): error is DirectusHttpError => error !== null),
        tap((error: DirectusHttpError) => this.handleError(error))
      )
      .subscribe();
  }

  private handleError(error: DirectusHttpError): void {
    // only handle 500 errors
    if (error.status === 500) {
      this.toastService.showError('toast_internal_server_error');
    }
  }
}
