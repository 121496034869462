import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { NgIf } from '@angular/common';
import {
  NgxGdprCookieConsentConfig,
  NgxGdprCookieConsentModule,
  NgxGdprCookieConsentService
} from 'ngx-gdpr-cookie-consent';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cookie-consent',
  standalone: true,
  imports: [NgIf, NgxGdprCookieConsentModule],
  templateUrl: './cookie-consent.component.html',
  styleUrl: './cookie-consent.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class CookieConsentComponent implements OnInit {
  private readonly cookieConsentService: NgxGdprCookieConsentService = inject(NgxGdprCookieConsentService);
  private readonly translate: TranslateService = inject(TranslateService);
  public config: NgxGdprCookieConsentConfig = {};

  public ngOnInit(): void {
    this.config = {
      backButtonText: this.translate.instant('buttons_back'),
      confirmAllButtonText: this.translate.instant('buttons_cookies_accept_all'),
      confirmButtonText: this.translate.instant('buttons_cookies_accept'),
      individualPrivacySettingsButtonText: this.translate.instant('buttons_cookies_settings'),
      individualPrivacySettingsSubtitle: this.translate.instant('labels_cookies_settings_subtitle'),
      subtitle: this.translate.instant('labels_cookies_subtitle'),
      title: this.translate.instant('labels_cookies_title'),
      legalLinks: [
        {
          name: this.translate.instant('buttons_privacy'),
          url: 'https://www.webpunks.at/datenschutz/'
        },
        {
          name: this.translate.instant('buttons_imprint'),
          url: 'https://www.webpunks.at/impressum/'
        }
      ]
    };
  }

  public onOpenModal(): void {
    this.cookieConsentService.showModal();
  }
}
