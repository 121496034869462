import { AppConstants } from '@app/app.constants';
import { CookieService } from 'ngx-cookie-service';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  private readonly cookieService: CookieService = inject(CookieService);

  public getCookie(name: string): string {
    return this.cookieService.get(AppConstants.STORAGE_PREFIX + name);
  }

  public setCookie(name: string, value: string): void {
    this.cookieService.set(AppConstants.STORAGE_PREFIX + name, value);
  }
}
