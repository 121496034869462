<!-- if not logged in  -->
<div class="header">
  <div class="boxed">
    <div class="header-grid">
      <div class="column-1">
        <!-- logo desktop -->
        <a [routerLink]="['/']" class="logo-link">
          <img height="15px" width="auto" src="assets/webpunks-secrets.svg" alt="WEBPUNKS" />
        </a>
      </div>
      <div class="column-2">
        <!-- login  -->
        <!-- <a class="login-header-link" [routerLink]="['/login']">Anmelden</a> -->
        <!-- sign up  -->
        <a
          target="_blank"
          href="https://chromewebstore.google.com/detail/secret-sharer/efpkkmabcoocmbipbphmoheaiidcbfll"
          title="Chrome Web Store">
          <img src="assets/chrome-web-store.png" alt="Available in the Chrome Web Store" />
        </a>
      </div>
    </div>
  </div>
</div>

<!-- if logged in  -->
<!-- <div class="header">
  <div class="boxed">
    <div class="header-grid">
      <div class="column-1"> -->
<!-- logo -->
<!-- logo desktop -->
<!-- <a [routerLink]="['/']" class="logo-link only-desktop">
          <img height="15px" width="auto" src="../../../assets/webpunks-secrets-left.png" alt="WEBPUNKS Logo" />
        </a> -->
<!-- logo mobile -->
<!-- <a [routerLink]="['/']" class="logo-link only-mobile">
          <img height="15px" width="auto" src="../../../assets/webpunks-secrets-center.png" alt="WEBPUNKS Logo" />
        </a>
      </div>
      <div class="column-2">
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</div> -->
