import { AppConstants } from '@app/app.constants';
import { DirectusError } from '@app/interfaces/directus-error.interface';
import { DirectusHttpError } from 'src/app/interfaces/directus-http-error.interface';
import { DirectusSchema } from '@app/interfaces/directus-schema.interface';
import {
  HttpMethod,
  RestClient,
  createDirectus,
  createItem,
  readItem,
  readItems,
  rest,
  updateItem
} from '@directus/sdk';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '@environment/environment';
@Injectable({
  providedIn: 'root'
})

/*
 * Base class for all other services. Sets some default values for all child services.
 * Also holds base configuration, depending on environment.
 */
export class DirectusService {
  public client: RestClient<DirectusSchema> = createDirectus(environment.apiUrl).with(rest());
  private readonly httpError: Subject<DirectusHttpError | null> = new Subject<DirectusHttpError | null>();

  /*
   * Used to read entity from the API
   */
  public async readItem<T>(collection: keyof DirectusSchema, id: string, query?: any): Promise<T | void> {
    return await this.client
      .request<T>(readItem(collection, id, query))
      .catch((error: unknown) => this.catchError(error as DirectusError));
  }

  /*
   * Used to call custom http endpoint
   */
  public async customRequest<T>(method: HttpMethod, path: string): Promise<T | null> {
    const response = (await this.client.request<T>(() => ({
      path,
      method
    }))) as Response;

    if (response && response.ok === false) {
      this.catchError(await response.json());
      return null;
    } else {
      this.httpError.next(null);
      return response as T;
    }
  }

  /*
   * Used to read entities from the API
   */
  public async readItems(collection: keyof DirectusSchema, query?: any): Promise<any[]> {
    return await this.client.request(readItems(collection, query));
  }

  /*
   * Used to create a new entity
   */
  public async createItem<T>(collection: keyof DirectusSchema, payload: any): Promise<T | void> {
    return await this.client
      .request<T>(createItem(collection, payload))
      .catch((error: unknown) => this.catchError(error as DirectusError));
  }

  /*
   * Used to update an entity
   */
  public async updateItem<T>(collection: keyof DirectusSchema, id: string, payload: any): Promise<T | void> {
    return await this.client
      .request<T>(updateItem(collection, id, payload))
      .catch((error: unknown) => this.catchError(error as DirectusError));
  }

  /*
   * Used to share http error within the application
   */
  public get currentError(): Observable<DirectusHttpError | null> {
    return this.httpError.asObservable();
  }

  /*
   * Used to catch errors and show them to the user
   */
  public catchError(directusError: any): void {
    if (directusError.errors && directusError.errors.length > 0) {
      const error: DirectusHttpError = {
        status: directusError.errors[0].status || directusError.response.status || 500,
        code: directusError.errors[0].extensions.code,
        message: directusError.errors[0].message
      };
      this.httpError.next(error);
      throw error;
    }
    throw new Error(AppConstants.UNKNOWN_ERROR);
  }
}
